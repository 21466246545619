import React, { useMemo } from 'react'

import { Button, PageHeader } from '../../common'
import { Paths } from '../../../constants/structure'
import { useProfile } from '../../../hooks'
import { formatUsDate } from '../../../common/utils/functions'
import { isStarterPlan } from '../../../common/utils/planTypes'

const APP_COMPANY = process.env.APP_COMPANY

const Subscriptions = () => {
   const { profile } = useProfile()

   const messageToDisplay = useMemo(() => {
      const isStarter = isStarterPlan(profile)
      const expiredEnterprise = !profile.EnterpriseUser

      if (isStarter) {
         return 'Please activate the subscription by clicking the button below and logging into our billing portal where you can manage billing information and user connections.'
      }
      if (expiredEnterprise) {
         return `Your subscription to ShareMyToolbox is expired as of ${formatUsDate(
            profile?.EntepriseExpiryDate
         )}. Please activate the subscription by clicking the button below and logging into our billing portal where you can manage billing information and user connections.`
      }
      return `Your subscription to ${APP_COMPANY} is active. Access our billing portal to  manage your subscription and number of user connections.`
   }, [profile])

   return (
      <div>
         <PageHeader title="Subscription" variant="wideMargin" />
         <p>{messageToDisplay}</p>
         <Button external type="link" href={Paths.SUBSCRIPTION_INFO}>
            Manage Subscriptions
         </Button>
      </div>
   )
}

export default Subscriptions
