import React from 'react';

import Subscriptions from '../../components/views/profile/subscriptions';
import { LayoutLoggedIn } from '../../components/views/layout';

export default function() {
    return (
        <LayoutLoggedIn>
            <Subscriptions />
        </LayoutLoggedIn>
    );
}